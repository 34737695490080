<template>
    <div class="industry_case_en_div">
        <div>
            <div class="case_banner">
                <img :src="imgSrc[0]"/>
            </div>
            <div class="case_all_div allContentWidth">
                <div class="tab_div">
                    <ul class="tab-title">
                        <li v-for="item in tabArr" :key="item.id" :class="{active:curActive==item.id}" @click="tabClickFun(item.id)">{{item.name}}</li>
                    </ul>
                </div>
                <div class="case_title_div">
                    <div class="case_title_img">
                        <!-- <img :src="imgSrc[1]"/> -->
                        <p>Solution </p>
                        <div class="line_div_box"></div>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="content-all-div">
                        <div class="content-left">
                            <p class="title_p">{{contentObj.title}}</p>
                            <div class="content_p">
                                <p v-for="cItem in contentObj.contentText" :key="cItem.id">{{cItem.texts}}</p>
                            </div>
                            <div class="content-bottom_div">
                                <div class="con_bottom_div" v-if="contentObj.bottom_content_left!==''">
                                    <div class="icon_div">
                                        <img :src="contentObj.icon_img_left"/>
                                    </div>
                                    <p class="p_size">{{contentObj.bottom_content_left}}</p>
                                    <div class="introduce_div">
                                        <p class="introduce_p" v-for="ul in contentObj.div_ul_left" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span  @click="routeLinkFun(ul)">{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div  class="con_bottom_div">
                                    <div class="icon_div">
                                        <img :src="contentObj.icon_img_right"/>
                                    </div>
                                    <p class="p_size">{{contentObj.bottom_content_right}}</p>
                                    <div class="introduce_div">
                                        <p class="introduce_p" v-for="ul in contentObj.div_ul_right" :key="ul.id">
                                            <b class="circle_b"></b>
                                            <span  @click="routeLinkFun(ul)">{{ul.value}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-right">
                            <img :src="contentObj.right_img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {
        this.params();
    },
    watch: {},
    methods: {
        params(){
            var query = location.search.substr(1);
            query = query.split('&')
            var params = {};
            for (let i = 0; i < query.length; i++) {
                let q = query[i].split('=')
                if (q.length === 2) {
                    params[q[0]] = q[1]
                }
            }
            if((JSON.stringify(params) === '{}')===false){ //判断是否为空对象
                this.curActive = Number(params.tabActive);
            }else{
                this.curActive = 1;
            }
            this.tabClickFun(this.curActive);
        },
        tabClickFun(id){
           this.curActive = id;
           if(this.contentAll!==undefined||this.contentAll.length!==0){
                this.contentAll.forEach((item)=>{
                    if(id === item.key){
                    this.contentObj = item;
                    }
                })
           }
        },
        routeLinkFun(item){
            if([11,12].indexOf(item.id)===-1){
                let query = 'tabActive=' + item.id;
                this.$router.push(`/main_business_en`+ `?` + query );
            }else{
                utils.scrollTo();
            }
        }
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/industry/case_banner.png'));//(0)
        imgSrc.push(require('../../assets/img/industry/case_title.png'));//(1)
        return {
            imgSrc: imgSrc,
            curActive: 1,
            tabArr:[
                {id: 1,name: 'PHARMACEUTICAL & HEALTHCARE'},//医药行业物流
                {id: 2,name: 'RETAIL'}, //零售业物流
                {id: 3,name: 'FURNITURE'}, //家具行业物流
                {id: 4,name: 'SPECIAL CARGO'},//能源、机械设备物流
            ],
            contentObj:{},
            contentAll:[
                {
                    key:1,
                    title: 'PHARMACEUTICAL & HEALTHCARE',//医药行业物流
                    contentText:[
                        {id: 1,texts:'Due to the particularity and sensitivity of pharmaceutical products, strict supervision and management are required in all aspects of the drug transportation process.'},
                        {id: 2, texts: 'In 2019, the coronavirus had a huge impact on the healthcare industry. Today, many pharmaceutical-related companies are looking for better logistics chain management to match the growing demand and unpredictable supply. Therefore, Eastrong embarked on introducing an effective plan and process to deal with the various risks that may arise. At the same time, it is extremely important to tightly control the end-to-end transportation process for such key materials and this was a critical element of the overall strategy of the company.'},
                        {id: 3,texts: 'In March 2022, after a series of strict audits, Eastrong was awarded the IATA global authoritative pharmaceutical logistics and transportation qualification certification - CEIV Pharma certification. We will strictly abide by international standards, carry out meticulous and standardized management and control of all aspects of drug transportation, and provide professional pharmaceutical logistics solutions. Our services encompass over-the-counter drugs, vaccines and serums, medical equipment or health supplements and other pharmaceutical-related products, and we are fully committed to adhere to high safety standards and to strictly abide by the regulatory policies of the transportation industry to provide comprehensive and thoughtful logistics solutions.'}
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: 'As your healthcare logistics partner, we can bring you the following advantages:',
                    bottom_content_right: 'Related Products and Services:',
                    div_ul_left: [
                        {id: 1, value: 'Professional team and high-quality customer service'},
                        {id: 2, value: 'GDP-compliant compliance process'},
                        {id: 3, value: 'Professional facilities and equipment'},
                        {id: 4, value: 'Real-time cargo tracking'},
                    ],
                    div_ul_right: [
                        {id: 2, value: 'Air Freight'}, //空运
                        {id: 3, value: 'Contract Logistics'}, //合约物流
                        {id: 1, value: 'Ocean Freight'}, //海运
                    ],
                    right_img: require('../../assets/img/industry/content_yywl.png')
                },
                {
                    key:2,
                    title: 'RETAIL', //零售业物流
                    contentText:[
                        {id: 1, texts: 'Eastrong provides professional and cost-effective logistics services to the retail industry, ensuring the rapid delivery of large quantities of goods and the efficient management of producers and suppliers in the supply chain. We can provide highly competitive retail industry solutions, fully assisting products to enter the market in ideal condition, efficiently and cost competitively.'},
                        {id: 2,texts: 'As an integrated logistics service provider, Eastrong operates a comprehensive transportation network including land, air, sea, rail and contract logistics. We can provide customers with reliable and on-time delivery services. In addition, we will provide a full tracking service, allowing customers to keep track of inventory levels, distribution routes and freight information during the course of transportation.'}
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: 'As your retail industry partner, we can bring you many advantages:',
                    bottom_content_right: 'Related Products and Services:',
                    div_ul_left: [
                        {id: 1, value: 'Professional team and excellent customer service'},
                        {id: 2, value: 'Own warehousing system and PO management system'},
                        {id: 3, value: 'The worlds leading freight tracking and inventory management system'},
                        {id: 4, value: 'Tailor made efficient solutions'},
                        {id: 5, value: 'Flexible emergency handling capabilities'},
                    ],
                    div_ul_right: [
                        {id: 2, value: ' Air Freight'}, //空运
                        {id: 1, value: 'Contract Logistic'}, //合约物流
                        {id: 3, value: 'Ocean Freight'}, //海运
                        {id: 4, value: 'Rail Service'}, //铁路
                    ],
                    right_img: require('../../assets/img/industry/content_lsywl.png')
                },
                {
                    key:3,
                    title: 'FURNITURE',//家具行业物流
                    contentText:[
                        {id: 1, texts: 'Eastrong has been deeply involved in the freight forwarding industry for more than 20 years, and has established long-term strategic collaborations with major domestic and foreign furniture customers. No matter how the freight market situation changes, we can provide efficient distribution services, stable space support, and real-time tracking of the shipments to fully meet the various needs of our customers.'},
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: 'As your furniture industry partner, we can bring you many advantages:',
                    bottom_content_right: 'Related Products and Services:',
                    div_ul_left: [
                        {id: 1, value: 'Fumigation service'},
                        {id: 2, value: 'Industry experience sharing'},
                        {id: 3, value: 'Procurement of supplier resources'},
                        {id: 4, value: 'Competitive shipping cost'},
                    ],
                    div_ul_right: [
                        {id: 1, value: 'Air Freight'}, //空运
                        {id: 3, value: 'Contract Logistics'}, //合约物流
                        {id: 4, value: 'Ocean Freight'}, //海运
                    ],
                    right_img: require('../../assets/img/industry/content_jjhywl.png')
                },
                {
                    key:4,
                    title: 'SPECIAL CARGO',//能源、机械设备物流
                    contentText:[
                        {id: 1, texts: 'For large and special equipment such as automobiles, solar equipment, over size shipments, heavy equipment, and precision instruments, there are strict requirements for transportation including fixed timelines and budget constraints. Eastrong is well aware of these challenges, especially for cross-border transportation of large equipment and the various risks that may arise in all aspects of the transportation process.'},
                        {id:2, texts:'For different types and sizes of cargo we will provide an effective transportation solution and carry out rigorous planning with continuous supervision of the entire process to actively respond to any risks that may be encountered. At all stages in the process, we will apply the most advanced solutions and technologies that are available on the market to ensure that our customers’ cargo arrives at its destination both on time and safely.'},
                        {id: 3,texts: 'With years of transportation experience and a reliable and stable supplier network, Eastrong has maintained close cooperation relations with many well-known bulk carriers, RORO operators and container ship owners. This cooperation covers transportation routes in Southeast Asia, the Middle East, Latin America, the Mediterranean and Australia. and other major importing countries of machinery and equipment. According to the different needs of our customers, we can provide you with professional customized logistics solutions.'}
                    ],
                    icon_img_left: require('../../assets/img/industry/jb_icon.png'),
                    icon_img_right: require('../../assets/img/industry/dq_icon.png'),
                    bottom_content_left: '',
                    bottom_content_right: 'Related Products and Services:',
                    div_ul_left: [],
                    div_ul_right: [
                        {id: 1, value: 'Ocean Freight'},//海运
                        {id: 3, value: 'Contract Logistics'},//合约物流
                        {id: 4, value: 'Rail Service'},//铁路
                        {id: 11, value: 'Project Service'},//项目服务-大件运输
                        {id: 12, value: 'DG Service'},//危险品运输
                    ],
                    right_img: require('../../assets/img/industry/content_nyjxwl1.png')
                }
            ],
        }
    }
}
</script>
<style lang="less">
.industry_case_en_div{
    .case_banner{
        width:100%;
        img{
            width:100%;
            height:100%;
        }
    }
    .case_all_div{
        //tab页
        .tab_div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 107px;
            margin-top: -60px;
            .tab-title{
                display: flex;
                align-items: center;
                height: 107px;
                background: #fff;
                box-shadow:  0px 1px 17px 1px rgba(0,0,0,0.1000);
                li{
                    text-align: center;
                    margin: 0 50px;
                    cursor: pointer;
                    font-size: 20px;
                    color: #232931;
                    position: relative;
                }
                li:hover::after{
                    width: 100%;
                }
                li::after{
                    transition: all 0.5s;
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 0px;
                    width: 0px;
                    height: 3px;
                    background: #2200ba;
                    // top: 40px;
                }
            }
            .tab-title .active {
                font-weight: 700;
                &::after{
                    width: 100%;
                }
			}
        }
        //标题
        .case_title_div{
            display: flex;
            justify-content: center;
            .case_title_img{
                margin: 50px 0 30px 0;
                font-size: 30px;
                font-weight: 700;
                .line_div_box{
                    background: #2200ba;
                    width: 30px;
                    height: 4px;
                    margin: auto;
                }
                img{
                    width:100%;
                    height:100%;
                }
            }
        }
        //tab页内容
        .tab-content{
            margin: 20px 0 80px 0;
            .content-all-div{
                display: flex;
                .content-left{
                    padding-right: 20px;
                    .title_p{
                        font-size: 28px;
                        font-weight: 600;
                        color: #404040;
                        line-height: 45px;
                    }
                    .content_p{
                        font-size: 12px;
                        font-weight: 400;
                        color: #656565;
                        line-height: 30px;
                        p{
                            margin: 20px 0 20px 0;
                        }
                    }
                    .content-bottom_div{
                        display: flex;
                        justify-content: space-between;
                        padding:50px 50px 0 0;
                        .con_bottom_div{
                            .icon_div{
                                height:37px;
                                width:37px;
                                margin-bottom: 10px;
                                img{
                                    width:100%;
                                    height:100%;
                                }
                            }
                            .p_size{
                                font-size: 16px;
                                font-weight: 600;
                                color: #404040;
                                line-height: 45px;
                            }
                            .introduce_div{
                                .introduce_p{
                                    .circle_b{
                                        width: 6px;
                                        height: 6px;
                                        background: #2200ba;
                                        display: inline-block;
                                        border-radius: 3px;
                                        margin-right: 10px;
                                    }
                                    span{
                                        font-size: 14px;
                                        color: #727272;
                                        line-height: 28px;
                                    }
                                    &:hover{
                                        cursor: pointer;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                        .con_bottom_div:first-child{
                            margin-right:20px;
                        }
                    }
                }
                // .content-right{
                //     width:538px;
                //     height: 522px;
                //     img{
                //         width:100%;
                //         height:100%;
                //     }
                // }
            }
        }
    }
}
@media screen and (max-width:900px){
    .industry_case_en_div{
        .case_all_div{
            //tab页
            .tab_div{
                margin-top: 0;
                height: 70px;
                .tab-title{
                    height: 70px;
                    li{
                        margin: 0 20px;
                        font-size: 12px;
                    }
                }
            }
            //tab页内容
            .tab-content{
                .content-all-div{
                    flex-direction: column;
                    .content-left{
                        padding: 0 20px;
                        .content-bottom_div{
                            flex-direction: column;
                            .con_bottom_div{
                                margin:0 0 20px 0;
                            }
                        }
                    }
                    .content-right{
                        width: 100%;
                        img{
                            width:100%;
                        }
                    }
                }
            }

        }
    }
}
</style>
